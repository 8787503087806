import {
  EyeIcon,
  EyeOffIcon,
  TextField,
  TextFieldButton,
} from '@translated/babele-react'
import {
  forwardRef,
  useState,
  type ComponentPropsWithoutRef,
  type ElementRef,
} from 'react'

export const PasswordField = forwardRef<
  ElementRef<typeof TextField>,
  Omit<ComponentPropsWithoutRef<typeof TextField>, 'type'>
>(function (props, ref) {
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  return (
    <TextField
      ref={ref}
      {...props}
      type={isPasswordShown ? 'text' : 'password'}
      endSlot={
        <TextFieldButton
          type="button"
          onClick={() => {
            setIsPasswordShown((s) => !s)
          }}
          aria-label={isPasswordShown ? 'Hide password' : 'Show password'}
        >
          {isPasswordShown ? <EyeOffIcon /> : <EyeIcon />}
        </TextFieldButton>
      }
    />
  )
})

PasswordField.displayName = 'PasswordField'
